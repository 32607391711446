<template>
  <form id="frm_action">
    <div class="p-formgrid" :key="key_form">

      <Toolbar class="fixed-bottom-toolbar div-button css-button-top-right">
        <template slot="right">
          <Button label="Back" @click="backToList()" id="back" class="main-style-button" />
        </template>
      </Toolbar>

      <div class="col-6">
        <!--        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Property</label>
          <div class="p-col">
            <v-select :clearable="false" title="Property" class="" @search="SearchProperty" placeholder="Choose" v-model="obj_data.property_id" :options="listProperty" :reduce="property_item => property_item.id" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="property">
          </div>
        </div>-->

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Property ID</label>
          <div class="p-col">
            <InputText maxlength="50" id="code" type="text" class="" v-model="obj_data.code" placeholder="Input"
              :disabled="mode == 'edit'" />
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Property name</label>
          <div class="p-col">
            <InputText maxlength="255" id="name" type="text" class="" v-model="obj_data.name" placeholder="Input" />
          </div>
        </div>
      </div>

      <div v-for="(item, index) in listLang" :key="item.id">
        <!-- Field for Service Name and Description -->

        <div class="col-12" v-if="item.iso_code === 'dk'">
          <div class="p-card-field">
            <label class="p-col-fixed txt-right txt-required">Description ({{ item.iso_code.toUpperCase() }})</label>
            <div class="p-col">
              <input class="input_tmp_validator" :id="'validator_content_' + item.iso_code">
              <CkditorCore :contentSet="item.content" @newdata="handleDataCkditor($event, index, 'content')" />
            </div>
          </div>
        </div>

        <!-- Checkbox to enable English fields -->
        <div class="col-6" v-if="item.iso_code === 'dk' && index === 0">
          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox v-model="obj_data.is_use_en" :binary="true" />
              <label class="p-col-fixed txt-right text">Use English</label>
            </div>
          </div>
        </div>

        <!-- English Section -->
        <div class="col-12" v-if="item.iso_code === 'en'">
          <div class="p-card-field">
            <label class="p-col-fixed txt-right txt-required">Description ({{ item.iso_code.toUpperCase() }})</label>
            <div class="p-col">
              <!-- input class="input_tmp_validator" :id="'validator_content_' + item_ll.iso_code"> -->
              <input class="input_tmp_validator" :id="'validator_content_' + item.iso_code">
              <CkditorCore :disabledCK="!obj_data.is_use_en" :contentSet="item.content"
                @newdata="handleDataCkditor($event, index, 'content')" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Type of property</label>
          <div class="p-col">
            <v-select :clearable="false" title="Property" class="" @search="SearchPropertyType" placeholder="Choose"
              v-model="obj_data.type_id" :options="listPropertyType" :reduce="item => item.id" label="name">
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="type_id">
          </div>
        </div>


        <div class="p-card-field style_box_room">
          <div class="box_room">
            <label class="p-col-fixed txt-right txt-required">Bedrooms</label>
            <div class="p-col">
              <InputNumber id="bedroom" class="" v-model="obj_data.bedroom" placeholder="Input" />
            </div>
          </div>
          <div class="box_room">
            <label class="p-col-fixed txt-right txt-required">Bathrooms</label>
            <div class="p-col">
              <InputNumber id="bathroom" class="" v-model="obj_data.bathroom" placeholder="Input" />
            </div>
          </div>
          <div class="box_room">
            <label class="p-col-fixed txt-right txt-required">Inside (m2)</label>
            <div class="p-col">
              <InputNumber id="acreage" placeholder="Input" v-model="obj_data.acreage" mode="decimal"
                :minFractionDigits="2" />
              <!--              <InputText id="acreage" type="text" class="" v-model="obj_data.acreage"  placeholder="Input"/>-->
            </div>
          </div>

          <div class="box_room">
            <label class="p-col-fixed txt-right txt-required">Outside (m2)</label>
            <div class="p-col">
              <InputNumber id="acreage" placeholder="Input" v-model="obj_data.outside" mode="decimal"
                :minFractionDigits="2" />
              <!--              <InputText id="acreage" type="text" class="" v-model="obj_data.acreage"  placeholder="Input"/>-->
            </div>
          </div>

          <div class="box_room">
            <label class="p-col-fixed txt-right txt-required">Parking</label>
            <div class="p-col">
              <InputNumber id="car_garage" class="" v-model="obj_data.car_garage" placeholder="Input" />
            </div>
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Country</label>
          <div class="p-col">
            <v-select :clearable="false" title="Property" class="" @search="SearchCountry" placeholder="Choose"
              v-model="obj_data.country_id" :options="listCountry" :reduce="item => item.id" label="name"
              @input="changeCountry">
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="country_id">
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">City</label>
          <div class="p-col">
            <v-select :clearable="false" title="Property" class="" @search="SearchState" placeholder="Choose"
              v-model="obj_data.state_id" :options="listState" :reduce="item => item.id" label="name"
              @input="changeState">
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="state_id">
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Address</label>
          <div class="p-col">
            <input class="input_tmp_validator" id="address_val">
            <!--            <InputText id="address" type="text" class="" v-model="obj_data.address"  placeholder="Input"/>-->
            <vue-google-autocomplete id="address" class="p-inputtext p-col-width pac-target-input" placeholder="Input"
              v-model="obj_data.address" @placechanged="handlePlaceChanged" />
          </div>
        </div>

        <div class="p-card-field style_box_lat_log">
          <div class="box_lat_log">
            <label class="p-col-fixed txt-right txt-required">Latitude</label>
            <div class="p-col">
              <InputText id="latitude" class="" v-model="obj_data.latitude" placeholder="Input" />
            </div>
          </div>
          <div class="box_lat_log">
            <label class="p-col-fixed txt-right txt-required">Longtitude</label>
            <div class="p-col">
              <InputText id="longtitude" class="" v-model="obj_data.longtitude" placeholder="Input" />
            </div>
          </div>
        </div>


        <!--        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Email</label>
          <div class="p-col">
            <InputText id="email" type="text" class="" v-model="obj_data.email"  placeholder="Input"/>
          </div>
        </div>-->
        <!--        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Phone number</label>
          <div class="p-col ">
            <div class="style-box-phone">
              <Dropdown  v-model="obj_data.prefix_phone" :options="list_phone_code" optionLabel="prefix_phone" optionValue="prefix_phone"/>
              <InputText type="number" id="phone" class="" v-model="obj_data.phone"  placeholder="Input"/>
            </div>
          </div>
        </div>-->
        <!--        <div class="p-card-field">
          <div class="row">
            <div class="col-6">
              <label class="p-col-fixed txt-right txt-required">Available for use from</label>
              <div class="p-col">
                <Calendar
                    :showOnFocus="false"
                    dateFormat="MM dd, yy"
                    :showIcon="true"
                    appendTo="body"
                    :showClear="true"
                    :manualInput="false"
                    v-model="obj_data.available_from" placeholder="Choose"
                />
                <input class="input_tmp_validator" id="available_from"/>
              </div>
            </div>

            <div class="col-6">
              <label class="p-col-fixed txt-right">To</label>
              <div class="p-col">
                <Calendar
                    :showOnFocus="false"
                    dateFormat="MM dd, yy"
                    id="end_booking_date"
                    :showIcon="true"
                    appendTo="body"
                    :showClear="true"
                    :manualInput="false"
                    v-model="obj_data.available_to"
                    placeholder="Choose"
                />
                <input class="input_tmp_validator" id="available_to"/>
              </div>
            </div>
          </div>
        </div>-->
        <!--        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Password</label>
          <div class="p-col">
            <InputText id="password" type="password" class="" v-model="obj_data.password"  placeholder="Input new password if you want to change"/>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Re-enter password</label>
          <div class="p-col">
            <InputText id="Re_enter_password" type="password" class="" v-model="obj_data.re_password"  placeholder="Input new password if you want to change"/>
          </div>
        </div>-->
      </div>


      <!-- <div class="col-12">
        <div class="p-card-field" v-for="(item_ll, index_ll) in listLang" :key="index_ll">
          <label class="p-col-fixed txt-right txt-required">Description
            ({{ item_ll.iso_code.toString().toUpperCase() }})</label>
          <div class="p-col">
            <input class="input_tmp_validator" :id="'validator_content_' + item_ll.iso_code">
            <CkditorCore :contentSet="item_ll.content" @newdata="handleDataCkditor($event, index_ll, 'content')">
            </CkditorCore>
          </div>
        </div>
      </div> -->


      <div class="col-6">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Images</label>
          <div class="p-col">
            <div class="cus-text">Recommend: 500x500px Maximum:10 MB</div>
            <div class="cus-text">Accepted file:JPG, JPEG, PNG, GIF.</div>
            <input class="p-inputtext p-component" accept="image/*" name="images[]" id="images_service" type="file"
              @change="onFileSelectFile" multiple /><!--@change="onFileSelectLogo"-->
            <input class="input_tmp_validator" id="list_file">
            <div class="cus-text style_list_image">
              <div class="box-image" v-for="(item, index) in list_file" :key="index">
                <img class="style_img" :src="item.objectURL" />
                <div class="button-clear" @click="onFileClear(item, index)"><img
                    src="/assets/images/icon/cancel_black_24dp.png" /></div>
              </div>
              <!--              <span :key="comFilename">{{ obj_data.filename }}</span>-->
            </div>

            <!--            <div class="select-button" style="background-color: rgb(227, 237, 252);height: max-content;text-align: justify;justify-items: center;display: flex;padding: 0.55rem;margin: -0.429rem;">
              <span style="margin: auto;">Tải lên</span>
            </div>-->
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right style_title_group ">PART ({{ total_length_part }}/5)</label>
          <!--          <div class="p-col">
          </div>-->
        </div>
        <div class="p-card-field" v-for="(item_p, index_p) in list_part" :key="index_p">
          <label class="p-col-fixed txt-right ">Part {{ item_p.part }}</label>
          <div class="p-col">
            <v-select :clearable="true" title="Property" class="" @search="SearchUser" placeholder="Choose"
              v-model="item_p.uid" :options="listUser" :reduce="item => item.id" label="name">
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <!--            <input class="input_tmp_validator" id="state_id">-->
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right style_title_group ">OTHER</label>
          <!--          <div class="p-col">
          </div>-->
        </div>

        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox v-model="obj_data.rent_for_thirdparty" :binary="true" />
            <label class="p-col-fixed txt-right text">Can create rent out request for Five2one members and 3rd party
              users</label>
          </div>
        </div>
        <div class="p-card-field " v-if="obj_data.rent_for_thirdparty == true">
          <div class="style_box_price">
            <label class="p-col-fixed txt-right txt-required">Price per day </label>
            <div class="p-col">
              <div class="p-inputgroup">
                <InputNumber id="thirdparty_price" placeholder="Input" v-model="obj_data.thirdparty_price" mode="decimal"
                :minFractionDigits="2" />
                <span class="p-inputgroup-addon">EUR</span>
              </div>
            </div>
          </div>
        </div>

        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox v-model="obj_data.rent_same_property" :binary="true" />
            <label class="p-col-fixed txt-right text">Can create rent out request for owners in the same
              property</label>
          </div>
        </div>
        <div class="p-card-field" v-if="obj_data.rent_same_property == true">
          <div class="style_box_price">
            <label class="p-col-fixed txt-right txt-required">Price per day</label>
            <div class="p-col">
                <div class="p-inputgroup">
                <InputNumber id="rent_same_property_price" placeholder="Input" v-model="obj_data.rent_same_property_price"
                mode="decimal" :minFractionDigits="2" />
                <span class="p-inputgroup-addon">EUR</span>
              </div>
            </div>
          </div>
        </div>

        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox v-model="obj_data.exchange_member" :binary="true" />
            <label class="p-col-fixed txt-right text">Can create exchange request for Five2one members</label>
          </div>
        </div>
        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox v-model="obj_data.exchange_same_property" :binary="true" />
            <label class="p-col-fixed txt-right text">Can create exchange request for owners in the same
              property</label>
          </div>
        </div>
        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox v-model="obj_data.active" :binary="true" />
            <label class="p-col-fixed txt-right text">Active</label>
          </div>
        </div>

        <!--        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">From - to</label>
          <div class="p-col">
            <Calendar 
              :showOnFocus="false" 
              dateFormat="MM dd, yy" 
              class=""
              :showIcon="true" 
              selectionMode="range"
              appendTo="body"
              :showClear="true" 
              :manualInput="false"
              v-model="from_to" :disabled="mode=='edit'" placeholder="Choose"
            />
            <input class="input_tmp_validator" id="from_to"/>
          </div>
        </div>-->
        <!--        <div class="p-card-field">
          <div class="row">
            <div class="col-6">
              <label class="p-col-fixed txt-right txt-required">Start booking</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  v-model="start_booking_date" placeholder="Choose"
                />
                <input class="input_tmp_validator" id="start_booking_date"/>
              </div>
            </div>
              
            <div class="col-6">
              <label class="p-col-fixed txt-right">End</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy" 
                  id="end_booking_date"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  :disabled="true"
                  v-model="end_booking_date"
                  placeholder="Choose"
                />
              </div>
            </div>
          </div>
        </div>-->
        <!--        <div class="p-card-field">
          <h3>ORDER OF BOOKING ({{partArr.length}}/5)</h3>
        </div>
        <packageDescriptions :key="componentKey" v-model="partArr" :mode="mode"/>
        
        <matrixComponent @updateMatrix="updateMatrix" v-model="partArr" :dataBook="dataBookingRound" :show="true" :property="selProperty" :key="`matrix-${componentKey}`"/>
        
        <div v-if="mode == 'edit'" class="p-card-field">
          <a @click="toCalendar" class="txt-link pl-3"><i>View on calendar</i></a>
        </div>-->
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button :label="$constants.TEXT_BUTTON.BACK" @click="backToList()" class="p-button-outlined" id="back" />
          <Button v-if="mode == 'edit'" :label="$constants.TEXT_BUTTON.EDIT" @click="saveData()"
            class="main-style-button" />
          <Button v-else :label="$constants.TEXT_BUTTON.ADD" @click="saveData()" class="main-style-button" />
        </template>
      </Toolbar>
    </div>
  </form>
</template>
<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
import CkditorCore from '../CkditorCore';

export default {
  components: {
    CkditorCore,
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      key_form:1,
      obj_data:{
        filename:"",
        prefix_phone:"",
      },
      comFilename:1,
      list_phone_code: [
        {
          prefix_phone:"+84",
        },
        {
          prefix_phone:"+45",
        }
      ],
      dataBookingRound: [],
      selProperty: null,
      listPropertyType: [],
      listPropertyTypeAll: [],
      listDocsCate: [],
      listDocsCateAll: [],
      listCountry: [],
      listCountryAll: [],
      mapCountryAll:{},
      listLang: [],

      listState: [],
      listStateAll: [],
      listUser: [],
      listUserAll: [],
      from_to: null,
      address: null,
      start_booking_date: null,
      end_booking_date: null,
      partArr: [],
      time_zone_local : moment.tz.guess(true),
      componentKey: 0,
      list_file :[],
      list_id_file_delete :[],
      maxFileSize: 2097152*50 , //100MB  2MB   /*104857600, // 100MB*/
      list_part:[
        {
          "uid": null,
          //"uid": "ee053528-31bb-4822-a620-c5664ecd1cd7",
          "part": "1",
          "order_booking": 1,
          "color": null
        },
        {
          "uid": null,
          //"uid": "79ed9b2a-ebfb-46d7-87d4-982e8cdb8f65",
          "part": "2",
          "order_booking": 2,
          "color": null
        },
        {
          "uid": null,
          //"uid": "321674aa-9161-4193-8422-5bd26a3224c2",
          "part": "3",
          "order_booking": 3,
          "color": null
        },
        {
          "uid":  null,
          //"uid":  "75629674-e721-493c-81b0-8ba014ae2aa0",
          "part": "4",
          "order_booking": 4,
          "color": null
        },
        {
          "uid":null,
          //"uid": "d9d3a852-1932-43b6-a2b8-b3ac0e7257cf",
          "part": "5",
          "order_booking": 5,
          "color": null
        }
      ]
    }
  },
  watch: {
    // "obj_data.is_use_en"(newValue) {
    //   const dkLang = this.listLang.find((lang) => lang.iso_code === "dk");
    //   const enLang = this.listLang.find((lang) => lang.iso_code === "en");

    //   if (newValue && dkLang && enLang) {
    //     // Khi bật `is_use_en`, gán nội dung của DK vào EN
    //     enLang.content = dkLang.content;
    //   } else if (!newValue && enLang) {
    //     // Khi tắt `is_use_en`, xóa nội dung của EN
    //     enLang.content = "";
    //   }
    // },
   /* async "selProperty"() {
      if (!this.$commonFuction.isEmptyObject(this.selProperty)) {
        this.address = this.selProperty?.address;
        this.partArr = this.selProperty?.part;
        if (this.mode == 'add') {
          var arr = [];
          this.partArr.map(m => {
            arr.push({ord: m.order_booking, id: m.id});
          })
          arr.map(m=> {
            var tmp = [];
            for (let i =0; i < arr.length; i++) {
              let a = m.ord - i + arr.length;
              if (a > arr.length) {
                a = a - arr.length;
              }
              tmp.push({ordinal: a, part_id: m.id, round: (i+1)});
            }
            this.dataBookingRound.push(tmp);
          })
        }
        this.componentKey++;
      }
    },
    "start_booking_date"() {
      if (this.partArr.length) {
        let days = 14 * this.partArr.length;
        this.end_booking_date = moment.tz(this.start_booking_date, this.time_zone_local).add(days, 'days').format("LL");
      }
    }*/
  },
  computed: {
    total_length_part() {
      var total = 0;
      for (var i=0;i<this.list_part.length;i++){
        var item_data =  this.list_part[i];
        if (!this.$commonFuction.isEmpty(item_data.uid)) {
          total++;
        }
      }
      return  total;
    },
  },
  async mounted() {
     this.getListPropertyType();
     this.getListCountry();
     this.getListUser();
    /*
    await this.getListDocsCate();*/
    console.log("this.model:",this.model)
    var dataLang = await this.$CoreService.getListLang({});
    var list_lang = dataLang.list;
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      this.getListState(this.model.country_id);
      this.obj_data = this.model;
      if (!this.$commonFuction.isEmpty(this.model?.list_file_edit)) {
        var list_file_edit = this.model?.list_file_edit
        for (let i = 0; i < list_file_edit.length; i++) {
          var item_file = list_file_edit[i]
          var obj = {id:item_file.id,'name': '', 'file': '', 'objectURL': item_file.url, type: 'old_edit'}//type:'old_edit' nếu gặp type này ko update avatar
          this.list_file.push(obj)
        }
      }
      var list_part =this.model?.part ?? []
      for (let g = 0; g < list_part.length; g++) {
          var item_part = list_part[g];
          var order_booking = item_part?.order_booking-1
          var uid_part = item_part?.uid
          this.list_part[order_booking]["uid"] =uid_part;
      }




      
      var lang_ser_arr = this.model?.lang_arr ?? [];
      var mapLS = {};
      for (let h = 0; h < lang_ser_arr.length; h++) {
        var itemLS = lang_ser_arr[h];
        mapLS[itemLS.id_lang] = itemLS;
      }

      for (let i = 0; i < list_lang.length; i++) {
        var itemL = list_lang[i];
        var id_l = itemL.id
        list_lang[i].content = (!this.$commonFuction.isEmpty(mapLS[id_l])) ? mapLS[id_l].description : '';
      }
      this.listLang = list_lang
      console.log("GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG", this.listLang);

/*
      this.obj_data.filename = this.model?.item_avatar?.file_name ?? ''
      if (!this.$commonFuction.isEmpty(this.model?.item_avatar)) {
        let url = this.model?.item_avatar?.url
        let obj = {'name': this.obj_data.filename, 'file': '', 'objectURL': url, type: 'old_edit'}//type:'old_edit' nếu gặp type này ko update avatar
        this.list_file.push(obj)
      }
      if (!this.$commonFuction.isEmpty(this.obj_data.available_from)) {
        this.obj_data.available_from = moment.tz(this.obj_data.available_from, this.time_zone_local).format("LL");
      }
      if (!this.$commonFuction.isEmpty(this.obj_data.available_to)) {
        this.obj_data.available_to = moment.tz(this.obj_data.available_to, this.time_zone_local).format("LL");
      }*/

      this.componentKey++;
    }else {
      //thêm mới

      for (let i = 0; i < list_lang.length; i++) {
        list_lang[i].content = '';
        list_lang[i].service_name = '';
      }
      this.listLang = list_lang
      this.obj_data = {
        active:true,
        /*"code": "code2",
        "name": "Villa 2024 -08 3",
        "type_id": "27191cac-7d56-4231-bc50-ee39befb8b4a",
        "country_id": "bea0b491-eb00-400f-b58a-40f6ec007d95",
        "state_id": "2a345c67-6cfd-4549-b327-ed067fa5031e",
        "address": "Mui Nè, Phan Thiet City, Binh Thuan Province",
        "latitude": 10.7981483,
        "longtitude": 106.6711895,
        "bedroom": 7,
        "bathroom": 10,
        "acreage": 4,
        "car_garage": 7,
        "rent_for_thirdparty": true,
        "thirdparty_price": 464564,
        "rent_same_property": true,
        "rent_same_property_price": 6787686,
        "exchange_member": true,
        "exchange_same_property": true,*/
        /*"part": [
          {
            "uid": "ee053528-31bb-4822-a620-c5664ecd1cd7",
            "part": "part 1",
            "order_booking": 1,
            "color": null
          },
          {
            "uid": "79ed9b2a-ebfb-46d7-87d4-982e8cdb8f65",
            "part": "part 2",
            "order_booking": 2,
            "color": null
          },
          {
            "uid": "321674aa-9161-4193-8422-5bd26a3224c2",
            "part": "part 3",
            "order_booking": 3,
            "color": null
          },
          {
            "uid": "75629674-e721-493c-81b0-8ba014ae2aa0",
            "part": "part 4",
            "order_booking": 4,
            "color": null
          },
          {
            "uid": "d9d3a852-1932-43b6-a2b8-b3ac0e7257cf",
            "part": "part 5",
            "order_booking": 5,
            "color": null
          }
        ],
        "admin_property_list": [
          {
            "uid": "ee053528-31bb-4822-a620-c5664ecd1cd7"
          },
          {
            "uid": "79ed9b2a-ebfb-46d7-87d4-982e8cdb8f65"
          },
          {
            "uid": "321674aa-9161-4193-8422-5bd26a3224c2"
          },
          {
            "uid": "75629674-e721-493c-81b0-8ba014ae2aa0"
          },
          {
            "uid": "d9d3a852-1932-43b6-a2b8-b3ac0e7257cf"
          }
        ],
        "file_image": [
          "a969a122-2513-4f89-9b3a-5ad170864ce2",
          "a969a122-2513-4f89-9b3a-5ad170864ce2",
          "a969a122-2513-4f89-9b3a-5ad170864ce2",
          "a969a122-2513-4f89-9b3a-5ad170864ce2",
          "a969a122-2513-4f89-9b3a-5ad170864ce2"
        ]*/
      };
      this.componentKey++;
    }
  },
  methods: {
    handleDataCkditor: function (e, index, key) {
      var dataContent = (e.length) ? e[0] : '';
      if (key == 'content') {
        //this.content = dataContent;
        this.listLang[index].content = dataContent;
      }
    }
    ,
    async changeState() {
      this.key_form++;
    },
    async changeCountry() {
      this.obj_data.state_id =null;
      this.getListState(this.obj_data.country_id);
      this.key_form++;
    },
    async onFileSelectFile(event) {
      var that = this;
      let files = event.target.files || event.dataTransfer.files;
      console.log("filesfiles:",files)
      console.log("files.length:",files.length)
      if(files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF', 'svg', 'SVG']
        console.log("files.length1111111:",files.length)
        for (let i = 0; i < files.length; i++) {
          console.log("files.333333333333:",files.length)
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Only post formats are supported: jpeg, jpg, gif, png, svg");
            return;
          }
        //  document.getElementById('file_name').innerText = files[i].name;
          console.log("files[i].namefiles[i].name:",files[i].name)
          that.obj_data.filename =files[i].name;
          this.comFilename++;
        }
        var arrTemp = [], arrFiles = [];
        for (let file of files) {
          console.log('file.sizefile.sizefile.size:',file.size)
          if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            console.log("asdasdasfile:",file)
            arrTemp.push(file);
          }
          if (file.size > this.maxFileSize) arrFiles.push(file);
        }
        if (arrFiles.length > 0) {
          alert("Your image is larger 100MB");
          that.obj_data.filename = "";
          return;
        } else {
          // if (this.icon_service.length > 0 && this.icon_service[0].file === '') {
          //   this.imagesDeletes.push(this.icon_service[0])
          // }
          console.log("arrTemparrTemp:",arrTemp)
          //this.list_file = [];
          this.list_file = this.list_file.concat.apply(this.list_file, arrTemp);
        }
      }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    updateMatrix(data) {
      console.log(data);
      this.dataBookingRound = [...data];
      // this.componentKey++;
    },
    async toCalendar() {
      this.$router.push(`/admin/list-booking?view=calendar&propery_id=${this.selProperty?.id}`);
    },
    async getListPropertyType(){
      var where_key = {
        deleted : {_eq : false},
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListPropertyType(where_key);
      this.listPropertyType =list.list;
      this.listPropertyTypeAll = list.list;
    },
    async SearchPropertyType(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
        };
        if (!this.$commonFuction.isEmpty(search)){
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListPropertyType(where_key);
        this.listPropertyType = list.list;
        //console.log("this.listProperty:",this.listProperty)
      }else{
        // cosnole.log("dasdasd")
        this.listPropertyType = this.listPropertyTypeAll
      }
    },
    async getListCountry(){
      var where_key = {
        deleted : {_eq : false},
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListCountry(where_key);
      this.listCountry =list.list;
      this.listCountryAll = list.list;
      this.mapCountryAll = list.map_list;
    },
    async SearchCountry(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
        };
        if (!this.$commonFuction.isEmpty(search)){
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListCountry(where_key);
        this.listCountry = list.list;
        //console.log("this.listProperty:",this.listProperty)
      }else{
        // cosnole.log("dasdasd")
        this.listCountry = this.listCountryAll
      }
    },
    async getListState(country_id =null){
      var where_key = {
        deleted : {_eq : false},
        // booking: {id: {_is_null: true} },
      }
      if (!this.$commonFuction.isEmpty(country_id)){
        where_key["country_id"] =  {_eq : country_id}
      }
      var list = await this.$CoreService.getListState(where_key);
      this.listState =list.list;
      this.listStateAll = list.list;
    },
    async SearchState(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
        };
        if (!this.$commonFuction.isEmpty(this.model.country_id)){
          where_key["country_id"] =  {_eq : this.model.country_id}
        }
        if (!this.$commonFuction.isEmpty(search)){
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListState(where_key);
        this.listState = list.list;
        //console.log("this.listProperty:",this.listProperty)
      }else{
        // cosnole.log("dasdasd")
        this.listState = this.listStateAll
      }
    },
    async getListUser(){
      var where_key = {
        deleted : {_eq : false},
        role : {_eq : this.$constants.ROLE_ACCOUNT.OWNER},
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListUser(where_key);
      this.listUser =list.list;
      this.listUserAll = list.list;
    },
    async SearchUser(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
        };
        if (!this.$commonFuction.isEmpty(search)){
          where_key.text_group_users = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListUser(where_key);
        this.listUser = list.list;
        //console.log("this.listProperty:",this.listProperty)
      }else{
        // cosnole.log("dasdasd")
        this.listUser = this.listUserAll
      }
    },

    async getListDocsCate(){
      var where_key = {
        deleted : {_eq : false},
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListDocsCate(where_key);
      this.listDocsCate =list.list;
      this.listDocsCateAll = list.list;
    },

    async SearchDocsCate(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
        };
        if (!this.$commonFuction.isEmpty(search)){
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListDocsCate(where_key);
        this.listDocsCate = list.list;
        //console.log("this.listProperty:",this.listProperty)
      }else{
        // cosnole.log("dasdasd")
        this.listDocsCate = this.listDocsCateAll
      }
    },

    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};

      if (this.mode == 'add') {
        let code_add = document.getElementById("code");
        if (this.$commonFuction.isEmptyObject(this.obj_data.code)) {
          code_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        } else {
          var checkCode = await this.checkCode(this.obj_data.code);
           console.log("checkCode",checkCode)
          if(checkCode == true){
            code_add.setCustomValidity("Property ID already exists!");
          }else{
            code_add.setCustomValidity("");
          }
        }
      }

      let name_add = document.getElementById("name");
      if (this.$commonFuction.isEmptyObject(this.obj_data.name)) {
        name_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        name_add.setCustomValidity("");
      }

      let type_id_add = document.getElementById("type_id");
      if (this.$commonFuction.isEmptyObject(this.obj_data.type_id)) {
        type_id_add.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        type_id_add.setCustomValidity("");
      }

      let bedroom_add = document.getElementById("bedroom");
      if (this.$commonFuction.isEmptyObject(this.obj_data.bedroom)) {
        bedroom_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        if (parseFloat(this.obj_data.bedroom) < 0){
          bedroom_add.setCustomValidity(this.$constants.MSG_TEXT.INT_MIN_VALIDATE);
        }else {
          bedroom_add.setCustomValidity("");
        }
      }

      let bathroom_add = document.getElementById("bathroom");
      if (this.$commonFuction.isEmptyObject(this.obj_data.bathroom)) {
        bathroom_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        if (parseFloat(this.obj_data.bathroom) < 0){
          bathroom_add.setCustomValidity(this.$constants.MSG_TEXT.INT_MIN_VALIDATE);
        }else {
          bathroom_add.setCustomValidity("");
        }
      }


      let acreage_add = document.getElementById("acreage");
      if (this.$commonFuction.isEmptyObject(this.obj_data.acreage)) {
        acreage_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        if (parseFloat(this.obj_data.acreage) < 0){
          acreage_add.setCustomValidity(this.$constants.MSG_TEXT.INT_MIN_VALIDATE);
        }else {
          acreage_add.setCustomValidity("");
        }
      }

      let car_garage_add = document.getElementById("car_garage");
      if (this.$commonFuction.isEmptyObject(this.obj_data.car_garage)) {
        car_garage_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        if (parseFloat(this.obj_data.car_garage) < 0){
          car_garage_add.setCustomValidity(this.$constants.MSG_TEXT.INT_MIN_VALIDATE);
        }else {
          car_garage_add.setCustomValidity("");
        }
      }

      let country_id_add = document.getElementById("country_id");
      if (this.$commonFuction.isEmptyObject(this.obj_data.country_id)) {
        country_id_add.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        country_id_add.setCustomValidity("");
      }

      let state_id_add = document.getElementById("state_id");
      if (this.$commonFuction.isEmptyObject(this.obj_data.state_id)) {
        state_id_add.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        state_id_add.setCustomValidity("");
      }



      let address_add = document.getElementById("address_val");
      if (this.$commonFuction.isEmptyObject(this.obj_data.address)) {
        address_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        address_add.setCustomValidity("");
      }


      let latitude = document.getElementById("latitude");
      if (this.$commonFuction.isEmptyObject(this.obj_data.latitude)) {
        latitude.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        latitude.setCustomValidity("");
      }

      let longtitude = document.getElementById("longtitude");
      if (this.$commonFuction.isEmptyObject(this.obj_data.longtitude)) {
        longtitude.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        longtitude.setCustomValidity("");
      }

      if (this.obj_data.rent_for_thirdparty ==true){
        let thirdparty_price = document.getElementById("thirdparty_price");
        if (this.$commonFuction.isEmptyObject(this.obj_data.thirdparty_price)) {
          thirdparty_price.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        } else {
          thirdparty_price.setCustomValidity("");
        }
      }


      if (this.obj_data.rent_same_property ==true){
        let rent_same_property_price = document.getElementById("rent_same_property_price");
        if (this.$commonFuction.isEmptyObject(this.obj_data.rent_same_property_price)) {
          rent_same_property_price.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        } else {
          rent_same_property_price.setCustomValidity("");
        }
      }
      let list_file = document.getElementById("list_file");
      if (this.list_file ==0) {
        list_file.setCustomValidity(this.$constants.MSG_TEXT.FILE_INVALID_MSG);
      } else {
        list_file.setCustomValidity("");
      }




      var list_lang = this.listLang
      for (let i = 0; i < list_lang.length; i++) {
        var itemData = list_lang[i];
        var iso_err = itemData.iso_code
        if (iso_err === 'dn') {
          let content = document.getElementById("validator_content_" + iso_err);
          if (this.$commonFuction.isEmpty(itemData.content)) {
            content.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
          } else {
            content.setCustomValidity("");
          }
        }
      }

      
      /*

      let list_file_add = document.getElementById("list_file");
      if (this.$commonFuction.isEmptyObject(this.obj_data.filename)){
        list_file_add.setCustomValidity(this.$constants.MSG_TEXT.FILE_INVALID_MSG);
      }else {
        list_file_add.setCustomValidity("");
      }*/

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },
    async checkCode(code){
     // if(this.email !== null && this.email !== ''){
        var where = {
          code: {'_eq': code},
          deleted: {'_eq': false},
         // type: {'_eq' : 'user'}
        }
        if (this.model !== undefined) where = Object.assign(where, {id: {'_neq': this.model.id}})
        var {data} = await this.$apollo.query({
          query: DataServices.getList('property', {"fields" : "id, name,code"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        })
        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      //}
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      var lastEmptyPart =null;
      for (let i = 0; i < this.list_part.length; i++) {
        var itemPart = this.list_part[i];
        if (i != 0){
            if (!this.$commonFuction.isEmpty(itemPart?.uid) && lastEmptyPart && this.$commonFuction.isEmpty(lastEmptyPart?.uid)) {
              console.log("lastEmptyPartlastEmptyPart:",lastEmptyPart)
              alert(`Please select part ${lastEmptyPart.order_booking}`)
              this.$commonFuction.is_loading(false);
              return;
            }
        }
        if (this.$commonFuction.isEmpty(itemPart.uid)) {
          lastEmptyPart = itemPart;
        }
      }
      //console.log("this.from_to", this.from_to);
      //console.log("partArr", this.dataBookingRound);
      var params = this.obj_data
      params["list_file"] = this.list_file
      params["part"] = this.list_part
      params["list_id_file_delete"] = this.list_id_file_delete
      params["list_lang"] = this.listLang

      params["time_zone"] = (!this.$commonFuction.isEmpty(this.mapCountryAll[this.obj_data.country_id])) ? this.mapCountryAll[this.obj_data.country_id]["time_zone"]:null

      console.log('saveDatasaveDatasaveData', params)
      this.$emit('save', params)
    },
    onFileClear(item,index) {
      console.log('itemitem:',item)
      var type = item?.type ?? null
      if (type =='old_edit'){
        this.list_id_file_delete.push(item.id);
      }
      this.list_file.splice(index, 1);
    },
    handlePlaceChanged(autocomplete) {
      console.log("autocomplete", autocomplete);
      if (autocomplete) {
        const lat = autocomplete.latitude;
        const long = autocomplete.longitude;

        // Lấy đầy đủ địa chỉ từ autocomplete
        const fullAddress = `${autocomplete.street_number} ${autocomplete.route}, ${autocomplete.administrative_area_level_2}, ${autocomplete.administrative_area_level_1}, ${autocomplete.country}`;

        this.obj_data.address = fullAddress;  // Gán đầy đủ địa chỉ vào obj_data.address
        this.obj_data.latitude = lat;
        this.obj_data.longtitude = long;
        this.key_form++;
      }
    }
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}
.p-inputtext{
  margin:  0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 760px!important;
  height: 48px!important;
}
.txt-right{
  font-weight: bold;
}

.multiselect{
  width: 760px!important;
}
.layout-main{
  background: #00000029;
}

.vs__clear{
  display: none;
}
.input_width{
  width: 760px!important;
  height: 48px!important;
}
#distance{
  width: 1px!important;
  height: 48px!important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}
.manager-title{
  font-size: 24px!important;
}
.input-dif{
  margin-left: 5px!important;
  border-top: 0px;
}
.p-inputgroup-addon{
  height: 48px ;
}
.txt-right{
  font-weight: bold;
}
.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.margin{
  margin-bottom: 12px!important;
}

.border_color{
  border: 1px solid #C8C8C8;
}
.text{
  font-weight: normal!important;
  margin-left: 15px!important;
}
.div_check{
  margin-top: 12px!important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
    width: 100%!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
  }
}
.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}
.cus-text{
  margin-left: 2px;
  margin-top: 4px;
}
.style-box-phone{
  border: 1px solid #a6a6a6;
  width: 100%;
  display: flex;
.p-dropdown{
  width: 100px;
  border: 0px;
  box-shadow: none !important;
}
  .p-focus{
    border: 0px;
    box-shadow: none ;
  }
  .p-dropdown:focus{
    border: 0px;
    box-shadow: none;
  }
  input{
    width: calc(100% - 100px);
    margin-left: 0px;
    border: 0px;
  }
  .p-inputtext:enabled:focus {
    border: 0px;
    box-shadow: none;
  }
}
.style_list_image {
  display: flex;
  flex-wrap: wrap; /* Cho phép các phần tử xuống dòng */
  gap: 10px; /* Khoảng cách giữa các phần tử */
  .box-image {
    width: 100px;
    height: 100px;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;

    .style_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #D0D4D9;
      border-radius: 10px;
    }
    .button-clear{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 1px;
    right: 8px;
  }
  }
}
.style_box_room{
  display: flex;
}
.style_box_lat_log{
  display: flex;
  .box_lat_log{
    width: 50%;
  }
}
.style_title_group{
  font: normal normal bold 20px/27px Segoe UI;
  letter-spacing: 0px;
  color: #666666;
  text-transform: uppercase;
}
.style_box_price{
  padding-left: 35px!important;
}
</style>
